import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { UserContext } from '../../../context/UserContext'
import Spinner from '../spinner/Spinner'
import { format } from 'date-fns'
import { Phone, Mail, Linkedin, Twitter, Github, Calendar, Edit, Trash2 } from 'lucide-react'

const UserDetail = () => {
  const { id } = useParams()
  const [profile, setProfile] = useState(null)
  const { user } = useContext(UserContext)

  const deleteUser = async (id) => {
    try {
      await axios.delete(`/api/users/${id}`)
      window.location.reload(true)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    axios
      .get(`/api/users/${id}`)
      .then((res) => {
        setProfile(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [id])

  if (!profile) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    )
  }

  const { firstName, lastName, email, createdAt, updatedAt, avatar, completed, position, linkedinUrl, githubUrl, twitterUrl, tel, bio } = profile
  const progress = ((completed.length / 30) * 100).toFixed(1)

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg space-y-6">
      {/* Profile Header */}
      <div className="flex items-center space-x-6">
        <img
          src={avatar}
          alt={firstName}
          className="w-24 h-24 rounded-full border-4 border-indigo-500 shadow-lg"
        />
        <div>
          <h2 className="text-2xl font-bold text-gray-800">{firstName} {lastName}</h2>
          <p className="text-sm text-gray-500">{position}</p>
        </div>
      </div>

      {/* Social Links */}
      <div className="flex space-x-4">
        {linkedinUrl && <a href={linkedinUrl} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800"><Linkedin /></a>}
        {twitterUrl && <a href={twitterUrl} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-600"><Twitter /></a>}
        {githubUrl && <a href={githubUrl} target="_blank" rel="noopener noreferrer" className="text-gray-800 hover:text-gray-600"><Github /></a>}
      </div>

      {/* Contact Info */}
      <div className="space-y-2 text-sm text-gray-600">
        {tel && <div className="flex items-center space-x-2"><Phone size={16} /><span>{tel}</span></div>}
        <div className="flex items-center space-x-2"><Mail size={16} /><span>{email}</span></div>
      </div>

      {/* Bio */}
      {bio && <p className="text-gray-700 text-sm leading-relaxed">{bio}</p>}

      {/* Dates */}
      <div className="text-sm text-gray-600 space-y-2">
        <div className="flex items-center space-x-2">
          <Calendar size={16} />
          <span>Joined on: {format(new Date(createdAt), 'MMM d, yyyy')}</span>
        </div>
        <div className="flex items-center space-x-2">
          <Calendar size={16} />
          <span>Last visited: {format(new Date(updatedAt), 'MMM d, yyyy')}</span>
        </div>
      </div>

      {/* Progress */}
      <div className="space-y-2">
        <label htmlFor='progress' className="font-medium text-gray-800">Your Progress:</label>
        <div className="flex items-center space-x-2">
          <progress id='progress' value={progress} max={100} className="w-full h-2 bg-indigo-200 rounded-full"></progress>
          <span className="text-sm font-semibold text-indigo-600">{progress}%</span>
        </div>
      </div>

      {/* Actions */}
      {user && (user.role[0] === 'Admin' || user.id === profile.id) && (
        <div className="flex space-x-4 mt-6">
          <Link to={`/users/edit/${id}`} className="flex items-center space-x-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            <Edit size={16} />
            <span>Edit</span>
          </Link>
          {user && user.role[0] === 'Admin' && (
            <Link to={'#'} onClick={() => deleteUser(id)} className="flex items-center space-x-2 bg-red-500 text-white px-2 py-2 rounded hover:bg-red-600">
              <Trash2 size={16} />
              <span>Delete</span>
            </Link>
          )}
        </div>
      )}
    </div>
  )
}

export default UserDetail
