import React from 'react'
import {Link} from 'react-router-dom'

const HomePage = () => {
  
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <header className="bg-indigo-600 text-white rounded-[20px]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 text-center">
          <h1 className="text-4xl font-extrabold sm:text-5xl md:text-6xl">
            Welcome to <span className="text-cyan-400 font-bold" >30DaysOfReact</span>
          </h1>

          <p className="mt-6 text-lg sm:text-xl max-w-3xl mx-auto">
            Master React in 30 days with structured lessons, real-world projects, and hands-on challenges. Join the journey to become a React pro!
          </p>
          <div className="mt-8 flex justify-center">
            <Link
              to={'/30-days-of-react'}
              className="inline-block bg-yellow-400 text-blue-800 px-8 py-4 rounded-lg font-semibold shadow-md hover:bg-yellow-300 focus:ring-2 focus:ring-yellow-500"
            >
              Get Started
            </Link>
          </div>
        </div>
      </header>

      {/* Highlights Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-gray-800 text-center">
            What You'll Learn
          </h2>
          <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0 w-12 h-12 bg-blue-100 text-blue-600 rounded-full flex items-center justify-center">
                <i className="fas fa-code"></i>
              </div>
              <div>
                <h3 className="text-lg font-semibold">React Basics</h3>
                <p className="text-gray-600">
                  Learn JSX, components, and state management from the ground up.
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0 w-12 h-12 bg-blue-100 text-blue-600 rounded-full flex items-center justify-center">
                <i className="fas fa-project-diagram"></i>
              </div>
              <div>
                <h3 className="text-lg font-semibold">Real-World Projects</h3>
                <p className="text-gray-600">
                  Build exciting projects like leaderboard, countries data visualization, weather dashboards, and more.
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0 w-12 h-12 bg-blue-100 text-blue-600 rounded-full flex items-center justify-center">
                <i className="fas fa-chalkboard-teacher"></i>
              </div>
              <div>
                <h3 className="text-lg font-semibold">Advanced Techniques</h3>
                <p className="text-gray-600">
                  Dive into hooks, context API, routing, and performance optimization.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section id="get-started" className="py-16 bg-indigo-600 text-white text-center">
        <h2 className="text-3xl font-extrabold">
          Ready to Transform Your React Skills?
        </h2>
        <p className="mt-4 text-lg">
          Sign up today and start your 30DaysOfReact journey.
        </p>
        <div className="mt-6">
          <Link
            to="/register"
            className="bg-yellow-400 text-blue-800 px-8 py-4 rounded-lg font-semibold shadow-md hover:bg-yellow-300 focus:ring-2 focus:ring-yellow-500"
          >
            Join Now
          </Link>
        </div>
      </section>

      
    </div>
  );
}

export default HomePage
