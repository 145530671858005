import React, {useState} from 'react'
import Post from './Post'
import {Container, Row} from 'reactstrap'
import Shimmer from '../../shared/Shimmer'
const Posts = ({posts}) => {
    // const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(true)
    const postList = posts.map((post) => <Post key={post.id} {...post} />)
    // useEffect(() => {
    //     fetchPosts()
    // }, [])
    // const fetchPosts = async () => {
    //     const url = '/api/posts'
    //     setLoading(true)
    //     const response = await fetch(url)
    //     const data = await response.json()
    //     setPosts(data)
    //     setLoading(false)
    // }
    return (
        <div className="container mx-auto px-4 py-8">
       <Row>
     {/*   {loading ? (
       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
        
        { Array(20).fill(0).map(() => <Shimmer /> )}
       
       </div>
       ) : (
      { postList}
       )} */}
       {postList}
       </Row>
        </div>
    )
}

export default Posts
