import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Navigate, useParams, useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import SocialShareButton from '../../components/courses/social-share/SocialShareButton'
import { UserContext } from '../../context/UserContext'
import { Link } from 'react-router-dom'
import Spinner from '../../components/courses/spinner/Spinner'
import Comments from '../../components/courses/comments/Comments'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { solarizedLight as theme } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import axios from 'axios'
import { Heart, MessageCircle, Eye, Edit, Trash } from 'lucide-react' // Lucide Icons
import './code-highlighter.css'
import Loading from '../../components/shared/Loading'

const CodeBlock = ({ string }) => {
  return (
    <SyntaxHighlighter language="js" style={theme}>
      {string}
    </SyntaxHighlighter>
  )
}

const SinglePost = () => {
  const [post, setPost] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { id } = useParams()
  const { user } = useContext(UserContext)
  const navigate = useNavigate()

  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`/api/posts/${id}`)
      setPost(response.data)
    } catch (err) {
      setError('Failed to fetch post')
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (post) {
      addView()
    }
  }, [id])

  const addView = async () => {
    try {
      await axios.post(`/api/posts/${id}/views`)
    } catch (err) {
      console.error('Error adding view:', err)
    }
  }

  const deletePost = async (id) => {
    try {
      await axios.delete(`/api/posts/${id}`)
      navigate('/')
    } catch (err) {
      console.error('Error deleting post:', err)
    }
  }

  const likePost = async (id) => {
    try {
      await axios.post(
        `/api/posts/${id}/likes`,
        {},
        { headers: { 'Content-Type': 'application/json' } }
      )
      fetchData() // Refresh post data to update likes count
    } catch (err) {
      console.error('Error liking post:', err)
    }
  }

  if (loading) return <Loading />
  if (error) return <div>{error}</div>

  return (
    <div className="container mx-auto px-2">
      <div className="grid grid-cols-1 gap-8">
        <div className="col-span-1 max-w-5xl mx-auto">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <img
                src={post.user?.avatar}
                alt={post.user?.firstName}
                className="w-10 h-10 rounded-full"
              />
              <div>
                <span className="font-semibold">
                  {post.user?.firstName} {post.user?.lastName}
                </span>
                <div className="text-sm text-gray-500">
                  {format(new Date(post.createdAt), 'MMM d, yyyy HH:mm')}
                </div>
              </div>
            </div>

            {user && user.role[0] === 'Admin' && user._id === post.user._id && (
              <div className="flex space-x-4">
                <Link
                  to={`/30-days-of-react/edit/${post._id}`}
                  className="text-blue-500 flex items-center space-x-1"
                >
                  <Edit size={16} />
                  <span>EDIT</span>
                </Link>
                <button
                  onClick={() => deletePost(post._id)}
                  className="text-red-500 flex items-center space-x-1"
                >
                  <Trash size={16} />
                  <span>DELETE</span>
                </button>
              </div>
            )}
          </div>

          <div className="my-4">
            <img src={post.cover.url} alt="" className="w-full rounded-lg" />
          </div>

          <div className="ql-snow">
            <div
              dangerouslySetInnerHTML={{ __html: post.content }}
              className="content ql-editor"
            />
          </div>

          <div className="mt-6">
            <div className="flex space-x-6 items-center">
              <span
                className="flex items-center space-x-1 cursor-pointer"
                onClick={() => likePost(post._id)}
              >
                <Heart size={16} />
                {post.likes.length}
              </span>
              <span className="flex items-center space-x-1">
                <MessageCircle size={16} />
                {post.comments.length}
              </span>
              <span className="flex items-center space-x-1">
                {post.views && (
                  <>
                    <Eye size={16} />
                    {post.views}
                  </>
                )}
              </span>
            </div>

            <div className="mt-6">
              <h3 className="text-xl font-semibold">Share on</h3>
              <SocialShareButton
                category="posts"
                id={post.id}
                hashtag="30 Days Of React"
              />
            </div>

            <div className="mt-6">
              <h2 className="text-2xl font-bold">Comments</h2>
              <Comments id={post.id} comments={post.comments} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

SinglePost.propTypes = {}

export default SinglePost
