import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { User, Mail, Lock } from 'lucide-react'
import {Link} from 'react-router-dom'

export const RegisterPage = () => {
  const initialState = {
    firstName: '',
    // lastName: '',
    email: '',
    password: '',
    password2: '',
  }

  const [formData, setFormData] = useState(initialState)
  const [loading, setLoading] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [errors, setErrors] = useState({})

  const onChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const { firstName, email, password, password2 } = formData
    if (password !== password2) {
      setErrors({ password: 'Passwords do not match' })
      return
    }
    try {
      setLoading(true)
      const url = '/api/users/register'
      const response = await axios.post(url, { firstName, email, password })
      console.log(response)
      setLoading(false)
      setRedirect(true)
    } catch (error) {
      setLoading(false)
      setErrors(error.response.data)
      console.log('REGISTRATION FAILED', error, error.response.data)
      toast.error('Registration failed. Please try again.')
    }
  }

  if (redirect) {
    return <Navigate to={'/login'} />
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-indigo-500 to-purple-500">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <form onSubmit={onSubmit} className="space-y-6">
          <h1 className="text-3xl font-semibold text-center mb-6 text-gray-800">Register</h1>

          {/* First Name */}
          <div className="mb-4">
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
            <div className="relative">
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={onChange}
                placeholder="Enter your first name"
                className={`w-full py-3 px-4 pr-10 rounded-lg border ${errors.firstName ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-300`}
              />
              {errors.firstName && <p className="text-red-500 text-sm mt-1">{errors.firstName}</p>}
            </div>
          </div>

          {/* Last Name */}
         {/*  <div className="mb-4">
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
            <div className="relative">
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={onChange}
                placeholder="Enter your last name"
                className={`w-full py-3 px-4 pr-10 rounded-lg border ${errors.lastName ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-300`}
              />
              {errors.lastName && <p className="text-red-500 text-sm mt-1">{errors.lastName}</p>}
            </div>
          </div> */}

          {/* Email */}
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <div className="relative">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={onChange}
                placeholder="Enter your email"
                className={`w-full py-3 px-4 pr-10 rounded-lg border ${errors.email ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-300`}
              />
              {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
            </div>
          </div>

          {/* Password */}
          <div className="mb-4">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
            <div className="relative">
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={onChange}
                placeholder="Enter your password"
                className={`w-full py-3 px-4 pr-10 rounded-lg border ${errors.password ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-300`}
              />
              {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
            </div>
          </div>

          {/* Confirm Password */}
          <div className="mb-6">
            <label htmlFor="password2" className="block text-sm font-medium text-gray-700">Confirm Password</label>
            <div className="relative">
              <input
                type="password"
                name="password2"
                value={formData.password2}
                onChange={onChange}
                placeholder="Confirm your password"
                className={`w-full py-3 px-4 pr-10 rounded-lg border ${errors.password2 ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-300`}
              />
              {errors.password2 && <p className="text-red-500 text-sm mt-1">{errors.password2}</p>}
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={loading}
            className={`w-full py-3 px-4 bg-indigo-500 text-white rounded-lg font-semibold hover:bg-indigo-600 focus:ring-2 focus:ring-indigo-400 transition duration-300 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {loading ? 'Registering...' : 'Sign Up'}
          </button>

          {/* Already have an account */}
          <div className="mt-4 text-center">
            <p className="text-sm text-gray-600">
              Already have an account?{' '}
              <Link to= {"/login"} className="text-blue-500 hover:underline">
                Log In
              </Link>
            </p>
          </div>
        </form>

        <ToastContainer />
      </div>
    </div>
  )
}

export default RegisterPage
