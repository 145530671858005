import React, { useState, useEffect, createContext } from 'react'
import axios from 'axios'

export const UserContext = createContext()

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const logout = () => {
    localStorage.removeItem('authToken')
    setToken(null)
    setUser(null)
    setIsAuthenticated(false)
  }

  useEffect(() => {
    const storedToken = localStorage.getItem('token')
    if (storedToken) {
      setToken(storedToken)
      setIsAuthenticated(true) // Set authentication status

      // Fetch user data using the stored token
     /*  axios
        .get('/api/users', {
          headers: { Authorization: `Bearer ${storedToken}` },
        })
        .then((response) => setUser(response.data))
        .catch(() => logout()) // If fetch fails, clear token and logout */
    }
  }, [])
  return (
    <UserContext.Provider value={{ user, setUser, isAuthenticated, setIsAuthenticated, token, setToken, logout }}>
      {children}
    </UserContext.Provider>
  )
}
