import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'highlight.js/styles/monokai-sublime.css'; // Highlight.js theme
import 'react-quill/dist/quill.core.css';
import hljs from 'highlight.js';

// Highlight.js Configuration
hljs.configure({
  languages: ['javascript', 'python', 'c', 'c++', 'java', 'html', 'css', 'matlab'],
});

// Quill Modules Configuration
const modules = {
  syntax: {
    highlight: (text) => hljs.highlightAuto(text).value, // Automatically detect language and highlight
  },
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [
      {
        font: [
          'Roboto',
          'Lora',
          'Lato',
          'Nunito',
          'Montserrat',
          'Poppins',
          'Raleway',
        ],
      },
    ],
    [{ color: [] }, { background: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['link', 'code-block'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video', 'formula'],
    [{ script: 'sub' }, { script: 'super' }],
    ['align', { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

// Quill Formats Configuration
const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

// Functional Component
const Editor = ({ value, onChange }) => {
  return (
    <ReactQuill
      theme="snow" // Theme
      value={value} // Value passed from parent component
      onChange={onChange} // Callback function to handle change
      modules={modules} // Toolbar and Syntax highlighting settings
      // formats={formats} // Allowed formats
      className="editor-container" // Custom class for styling
    />
  );
};

export default Editor;
