import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
/* import 'bootstrap/dist/css/bootstrap.min.css'; */

/* import './assets/styles/footer.scss' */
// import './components/day_24/countries.scss'

/* import './assets/styles/normalize.css'
import './assets/styles/common.scss'
import './assets/styles/header.scss'
import './assets/styles/footer.scss'
import './assets/styles/about.scss'
import './assets/styles/buttons.scss'
import './assets/styles/icons.scss'
import './assets/styles/form.scss'
import './assets/styles/main.scss' */

// import './assets/styles/landing.scss'
// import './assets/styles/sections.scss'
import './index.scss'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
