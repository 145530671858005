import React from 'react'
import axios from 'axios'
/* import Home from './components/Home'
import About from './components/About'
import Contact from './components/Contact'
import Students from './components/student/Students'
import StudentDetail from './components/student/StudentDetail'
import AddMember from './components/student/AddStudent'
import EditStudent from './components/student/EditStudent'
import NewsList from './components/news/NewsList'
import NewsDetail from './components/news/NewsDetail'
import EditNews from './components/news/EditNews'
import Blogs from './components/blog/Blogs'
import BlogDetail from './components/blog/BlogDetail'
import EditBlog from './components/blog/EditBlog'
import Events from './components/event/Events'
import EventDetail from './components/event/EventDetail'
import EditEvent from './components/event/EditEvent'
import Admin from './components/admin/Admin'
import Image from './components/gallery/Image'
import ImageDetail from './components/gallery/ImageDetail'
import Images from './components/gallery/Images'
import EditImage from './components/gallery/EditImage'
import { Provider } from 'react-redux'
import PrivateRoute from './components/shared/PrivateRoute'
import store from './store/store'

import ActivationEmailMessage from './components/auth/ActivationEmailMessage'
import SignUp from './components/auth/SignUp'
import SignIn from './components/auth/SignIn'

import UserActivation from './components/auth/UserActivation'
import ResetPassword from './components/auth/ResetPassword'
import ForgotPassword from './components/auth/ForgotPassword'
import CheckYourEmail from './components/auth/CheckYourEmail'

import setAuthToken from './utils/setAuthToken'
import jwt_decode from 'jwt-decode'
import { signOut, setCurrentUser } from './store/actions/authActions'
 */
import Message from './components/Message'
import MovingBox from './components/day_11/MovingBox'

import CatParadiseDay20 from './components/day_20/CatParadiseDay20'
import CatParadiseDay19 from './components/day_19/CatParadiseDay19'
import Projects from './Projects'
import CountriesData from './components/day_24/CountriesData'
import DummyDataGenerator from './components/dummy_data_generator/DummyDataGenerator'
import ColorGeratorApp from './components/day_27_color-app/ColorGeneratorApp'
import TwitterApp from './components/day_28_twitter/TwitterApp'

import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Login from './pages/auth/Login'
import Register from './pages/auth/Register'
import CreatePost from './pages/post/CreatePost'
import Layout from './components/courses/layout/Layout'
import Home from './pages/Home'
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './App.css'
import { UserContextProvider } from './context/UserContext'
import SinglePost from './pages/post/SinglePost'
import EditPost from './pages/post/EditPost'
import About from './pages/About'
import Challenges from './components/Challenges'

import Profile from './components/courses/user/Profile'
import Dashboard from './pages/Dashboard'
import Users from './components/courses/user/Users'
import User from './components/courses/user/User'
import UserDetail from './components/courses/user/UserDetails'
import EditUser from './components/courses/user/EditUser'
import ThirthyDaysOfReact from './pages/30DaysOfReact'
axios.defaults.withCredentials = true
const App = () => {
  return (
    <UserContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path={'/about'} element={<About />} />
            <Route path={'/30-days-of-react'} element={<ThirthyDaysOfReact />} />
            <Route path={'/login'} element={<Login />} />
            <Route path={'/register'} element={<Register />} />

            <Route path={'/users/:id'} element={<UserDetail />} />
            <Route path={'/users/edit/:id'} element={<EditUser />} />
            <Route path={'/30-days-of-react/create'} element={<CreatePost />} />
            <Route path={'/30-days-of-react/:id'} element={<SinglePost />} />
            <Route path={'/30-days-of-react/edit/:id'} element={<EditPost />} />
            <Route path={'/dashboard'} element={<Users />} />
            <Route path={'/profile/:id'} element={<UserDetail />} />
            <Route path="/day-19/cats" element={<CatParadiseDay19 />} />
            <Route path="/day-20/cats" element={<CatParadiseDay20 />} />
            <Route path="/moving-box" />
            <Route path="/day-23/countries-data" element={<CountriesData />} />
            <Route
              path="/dummy-data-generator"
              element={<DummyDataGenerator />}
            />
            <Route path="/day-28/twitter-clone" element={<TwitterApp />} />
            <Route
              path="/day-27/hexadecimal-colors"
              element={<ColorGeratorApp />}
            />
            <Route path="/challenges" element={<Challenges />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </UserContextProvider>
  )
}

export default App
