import React from 'react'
import { Linkedin, Twitter, Github, Calendar, Edit, Trash2 } from 'lucide-react'


const About = () => {
  return (
    <div className='container mx-auto p-10 bg-white shadow-xl rounded-lg border border-gray-200'>
      <section className='space-y-8'>
        <h4 className='text-4xl font-bold mb-6 text-blue-900 flex items-center'>
          <a href='#introduction' className='text-blue-500 hover:text-blue-700 mr-3'>
            #
          </a>
          30 Days Of React Challenge
        </h4>
        <article id='introduction' className='text-gray-700 leading-relaxed text-lg space-y-6'>
          <h3 className='text-2xl font-semibold text-blue-800'>Course Description:</h3>
          <p>
            Unleash your potential with our comprehensive "30 Days Of React Challenge." This meticulously designed course serves as your stepping stone into the world of React, equipping you with the skills and knowledge needed to excel in web development. With a strong focus on hands-on learning, you'll embark on a journey that will transform you into a proficient React developer.
          </p>
          <h3 className='text-2xl font-semibold text-blue-800'>Objectives:</h3>
          <ul className='list-disc list-inside space-y-3 pl-4 text-blue-700'>
            <li>Understand the fundamental concepts of React.</li>
            <li>Gain proficiency in creating JSX Elements.</li>
            <li>Master the art of rendering JSX Elements.</li>
            <li>Learn to inject data into JSX elements for dynamic content.</li>
            <li>Explore styling and class management with React.</li>
            <li>Dive deep into the world of React Components.</li>
            <li>Develop a strong foundation in Functional and Class-based Components.</li>
            <li>Harness the power of Props to create versatile React applications.</li>
            <li>Understand the intricacies of managing States.</li>
            <li>Master event handling in React applications.</li>
            <li>Create dynamic and interactive forms.</li>
            <li>Implement conditional rendering techniques.</li>
            <li>Explore controlled and uncontrolled input elements.</li>
            <li>Dive into the Component Life Cycle for optimal application performance.</li>
            <li>Navigate through different routes with React Router.</li>
            <li>Unlock the potential of React Hooks for efficient state management.</li>
          </ul>
          <div className="max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
      <div className="text-center">
        <h1 className="text-3xl font-bold mb-4">About the Author</h1>
        <p className="text-lg text-gray-700 mb-6">
          <strong>Author:</strong> Asabeneh Yetayeh
        </p>
        <p className="text-gray-600 mb-6 leading-relaxed">
          This course is your gateway to becoming a proficient React developer, and upon completion, 
          you'll be eligible to claim a certificate of achievement. By tackling a variety of complex 
          applications, you'll not only bolster your knowledge but also prepare yourself for a future as 
          a Junior Software Developer. Join us on this exciting journey of discovery and growth in the world of React!
        </p>
        <div className="flex space-x-4">
        <a href={'https://www.linkedin.com/in/asabeneh/'} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800"><Linkedin /></a>
 <a href={'https://x.com/Asabeneh'} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-600"><Twitter /></a>
<a href={'https://github.com/Asabeneh'} target="_blank" rel="noopener noreferrer" className="text-gray-800 hover:text-gray-600"><Github /></a>

</div>
      </div>
    </div>
        </article>
      </section>
    </div>
  )
}

export default About

