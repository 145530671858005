import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '../../context/UserContext'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.bubble.css'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.core.css'
import hljs from 'highlight.js'
import { Navigate, useParams } from 'react-router-dom'
import Editor from '../../components/courses/Editor'
import { Col, Row } from 'reactstrap'

const EditPost = (props) => {
  const initialState = {
    title: '',
    subtitle: '',
    category: '',
    summary: '',
    content: '',
    file: '',
    video: '',
  }
  const [formData, setFormData] = useState(initialState)
  const [files, setFiles] = useState('')
  const [cover, setCover] = useState('')
  const [loading, setLoading] = useState(false)
  const { user, setUser } = useContext(UserContext)
  const [content, setContent] = useState('')
  const [redirect, setRedirect] = useState(false)
  const { id } = useParams()
  const onChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }
  useEffect(() => {
    fetchPost()
  }, [])
  const fetchPost = async () => {
    const url = `/api/posts/${id}`
    const response = await fetch(url)
    const data = await response.json()
    const { title, subtitle, category, summary, cover } = data
    setFormData({ title, subtitle, category, summary, content, file: cover })
    setContent(data.content)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append('title', formData.title)
    data.append('subtitle', formData.subtitle)
    data.append('summary', formData.summary)
    data.append('content', content)
    data.append('category', formData.category)
    data.append('id', id)
    if (formData.file) {
      data.append('file', formData.file)
    }

    try {
      const url = `/api/posts/${id}`
      const response = await fetch(url, {
        method: 'PUT',
        body: data,
      })
      console.log('Response:', response)
      if (response.ok) {
        setRedirect(true)
      }
      console.log(response)
    } catch (error) {
      console.log('Post create error', error)
    }
  }
  if (!user) {
    return <Navigate to={'/login'} />
  }
  if (redirect) {
    return <Navigate to={`/posts/${id}`} />
  }

  return (
    <div className="min-h-screen bg-gray-50 py-10">
    <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">Update a Post</h1>
      <form onSubmit={onSubmit}>
      <div>
            <label className="block text-gray-700 font-medium mb-2">Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={onChange}
              placeholder="Enter the post title"
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Subtitle</label>
            <input
              type="text"
              name="subtitle"
              value={formData.subtitle}
              onChange={onChange}
              placeholder="Enter the post subtitle"
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>

          <div>
            <label className="block text-gray-700 font-medium mb-2">Summary</label>
            <textarea
              name="summary"
              value={formData.summary}
              onChange={onChange}
              placeholder="Write a brief summary of your post"
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
              rows="4"
            />
          </div>

          <div>
            <label className="block text-gray-700 font-medium mb-2">Category</label>
            <input
              type="text"
              name="category"
              value={formData.category}
              onChange={onChange}
              placeholder="Enter the category"
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>

        <Editor
          theme="snow"
          value={content}
          name="content"
          onChange={setContent}
          className="editor-container"
        />
        {/* <textarea name="content" id="" cols="90" rows="10"></textarea> */}
        <input
          type="file"
          onChange={(e) =>
            setFormData({ ...formData, file: e.target.files[0] })
          }
          accept="image/*"
        />
        {formData && formData.file && formData.file.url && (
          <Col>
            {' '}
            <img src={formData.file.url} className="img-fluid" alt="" />
          </Col>
        )}

        {formData && formData.file && formData.file.name && (
          <Col>
            <img
              src={URL.createObjectURL(formData.file)}
              className="img-fluid"
              alt=""
            />
          </Col>
        )}
        <button style={{ marginTop: '5px' }}>Update Post</button>
      </form>
      </div>
      </div>
  )
}

export default EditPost
