import React from 'react'
import Header from '../header/Header'
import { Outlet } from 'react-router-dom'

const Layout = () => (
  <div className="flex flex-col min-h-screen bg-gray-50">
    <Header />
    
    <main className="flex-grow">
      <div className="container mx-auto px-4 py-6">
        <Outlet />
      </div>
    </main>

   {/* Footer */}
   <footer className="bg-gray-800 text-gray-400 py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <p>&copy; 2025 30DaysOfReact. All rights reserved.</p>
        </div>
      </footer>
  </div>
)

export default Layout
