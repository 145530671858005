import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import UserTable from './UserTable'
import { NavLink, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { UserContext } from '../../../context/UserContext'

const Users = (props) => {
  const [users, setUsers] = useState([])
  const { user, setUser } = useContext(UserContext)
  const navigate = useNavigate()

  const getUsers = async () => {
    const response = await axios.get('/api/users')
    setUsers(response.data)
  }

  useEffect(() => {
    if (user && user.role[0] === 'Admin') {
      getUsers()
    } else {
      navigate('/')
    }
  }, [navigate, user])

  if (users.length < 0) {
    return <div className="text-center">Loading...</div>
  }

  const userList = <UserTable users={users} />

  return (
    <div className="max-w-6xl mx-auto p-6 space-y-6">
      <div className="flex justify-end space-x-4 mb-6">
        <NavLink
          to="/users/table"
          className="p-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-all duration-200"
        >
          <i className="bi bi-table text-2xl"></i>
        </NavLink>
        <NavLink
          to="/users/card"
          className="p-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-all duration-200"
        >
          <i className="bi bi-card-heading text-2xl"></i>
        </NavLink>
      </div>
      <div>
        <h3 className="text-2xl font-semibold">Number of users ({users.length})</h3>
        <div className="my-4">{userList}</div>
      </div>
    </div>
  )
}

export default Users
