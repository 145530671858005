import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../../context/UserContext';
import './UserTable.scss';

const UserTable = (props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { user: currentUser } = useContext(UserContext);

  const filterUser = (term) => {
    const search = term.toLowerCase();
    return term
      ? props.users.filter(
          ({ firstName, lastName, email, country, position }) =>
            firstName.toLowerCase().includes(search) ||
            lastName.toLowerCase().includes(search) ||
            email.toLowerCase().includes(search) ||
            country?.toLowerCase().includes(search) ||
            position?.toLowerCase().includes(search)
        )
      : props.users;
  };

  const deleteUser = async (id) => {
    try {
      await axios.delete(`/api/users/${id}`);
      window.location.reload(true);
    } catch (error) {
      console.log(error);
    }
  };

  const data = filterUser(searchTerm);
  const rows = data.map((user, index) => (
    <tr key={user.id} className="hover:bg-gray-50 cursor-pointer">
      <td className="py-3 px-4">{index + 1}</td>
      <td className="py-3 px-4">
        <Link to={`/users/${user.id}`}>
          <img
            src={user.avatar}
            className="rounded-full w-12 h-12 object-cover border border-gray-300"
            alt="avatar"
          />
        </Link>
      </td>
      <td className="py-3 px-4 text-gray-900">{user.firstName}</td>
      <td className="py-3 px-4 text-gray-900">{user.lastName}</td>
      <td className="py-3 px-4 text-gray-700">{user.email}</td>
      <td className="py-3 px-4 text-gray-500">
        <Moment format="D MMM YYYY HH:mm">{user.createdAt}</Moment>
      </td>
      <td className="py-3 px-4">
        {user.gender === 'male' ? (
          <i className="bi bi-gender-male text-blue-500"></i>
        ) : (
          <i className="bi bi-gender-female text-pink-500"></i>
        )}
      </td>
      {currentUser?.role[0] === 'Admin' && (
        <td className="py-3 px-4 flex space-x-3">
          <Link to={`/users/edit/${user.id}`} className="text-blue-600 hover:text-blue-800 transition-colors">
            <i className="fas fa-edit"></i>
          </Link>
          <button
            onClick={() => deleteUser(user.id)}
            className="text-red-600 hover:text-red-800 transition-colors"
          >
            <i className="fas fa-trash-alt"></i>
          </button>
        </td>
      )}
    </tr>
  ));

  const headers = (
    <thead className="bg-gray-100">
      <tr>
        <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">No.</th>
        <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">Avatar</th>
        <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">First Name</th>
        <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">Last Name</th>
        <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">Email</th>
        <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">Joined on</th>
        <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">Gender</th>
        {currentUser?.role[0] === 'Admin' && <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">Actions</th>}
      </tr>
    </thead>
  );

  return (
    <div className="max-w-6xl mx-auto p-6">
      <div className="my-4 flex items-center space-x-3 border-b pb-4">
        <input
          type="text"
          placeholder="Search user by name, email, position ..."
          className="p-2 border border-gray-300 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-collapse text-sm">
          {headers}
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  );
};

UserTable.propTypes = {
  users: PropTypes.array.isRequired,
};

export default UserTable;
