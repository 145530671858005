import React from 'react'
import { Link } from 'react-router-dom'
import { Heart, MessageCircle, Eye } from 'lucide-react' // Using lucide-react icons
import Spinner from '../../shared/Spinner'

const Post = (props) => {
  if (!props) {
    return <Spinner />
  }

  const {
    title,
    summary,
    content,
    comments,
    likes,
    views,
    createdAt,
    cover,
    user,
    id,
    status,
  } = props

  const postCompleted = status?.completed ? 'bg-green-100' : 'bg-white'

  return (
    <div
      className={`mt-5 mb-5 p-4 rounded-lg shadow-lg bg-white hover:bg-gray-100 hover:shadow-xl transform hover:scale-105 transition-all duration-300`}
    >
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/3">
          <Link to={`/30-days-of-react/${id}`}>
            <img
              className="w-full h-auto rounded-md"
              src={cover.url}
              alt="Post Cover"
            />
          </Link>
        </div>

        <div className="md:w-2/3 md:pl-6">
          <Link to={`/30-days-of-react/${id}`}>
            <h2 className="text-2xl font-semibold text-gray-800 hover:text-blue-500">
              {title}
            </h2>
          </Link>

          {/* Post stats with hover effects */}
          <div className="mt-4 flex space-x-6 text-sm text-gray-600">
            <span className="flex items-center space-x-1 hover:text-red-500 cursor-pointer transition-all duration-300 transform hover:scale-105">
              <Heart className="w-5 h-5" />
              {likes.length > 0 && likes.length}
            </span>

            <span className="flex items-center space-x-1 hover:text-blue-500 cursor-pointer transition-all duration-300 transform hover:scale-105">
              <MessageCircle className="w-5 h-5" />
              {comments.length > 0 && comments.length}
            </span>

            {views && (
              <span className="flex items-center space-x-1 hover:text-gray-500 cursor-pointer transition-all duration-300 transform hover:scale-105">
                <Eye className="w-5 h-5" />
                {views}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Post
