
import { Loader } from 'lucide-react'; // Importing Loader from lucide-react

const Loading = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50">
      <div className="relative flex flex-col items-center justify-center">
        <div className="animate-spin text-blue-500">
          <Loader className="h-12 w-12" />
        </div>
        <p className="text-white mt-4 text-lg">Loading...</p>
      </div>
    </div>
  );
};

export default Loading;
