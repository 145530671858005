import React, { useState, useEffect } from 'react'
import Posts from '../components/courses/post/Posts'
import Shimmer from '../components/shared/Shimmer'

const ThirtyDaysOfReact = () => {
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    fetchPosts()
  }, [])

  const fetchPosts = async () => {
    const url = '/api/posts'
    setLoading(true)
    const response = await fetch(url)
    const data = await response.json()
    setPosts(data)
    setLoading(false)
  }
  if (loading) {
    return  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
        
    { Array(20).fill(0).map(() => <Shimmer /> )}
   
   </div>
  }
  if (posts.length === 0) {
    return <h2>No posts found</h2>
  }

  return <>{posts.length > 0 && <Posts posts={posts} />}</>

}

export default ThirtyDaysOfReact
