import React, { useState, useContext } from 'react'
import { UserContext } from '../../context/UserContext'
import { Navigate } from 'react-router-dom'
import { Container,Row } from 'reactstrap'
import '../../assets/styles/create-post.scss'
import Editor from '../../components/shared/Editor'




const CreatePost = (props) => {
  const initialState = {
    title: '',
    subtitle: '',
    category: '',
    summary: '',
    content: '',
    file: '',
    video:{}
  }
  const [formData, setFormData] = useState(initialState)
  const [loading, setLoading] = useState(false)
  const {user, setUser } = useContext(UserContext)
  const [content, setContent] = useState('')
  const [redirect, setRedirect] = useState(false)
  const [preview, setPreviw] = useState('')
  const [progress, setProgress] = useState(0);
  const [visible, setVisible] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [uploadButtonText, setUploadButtonText] = useState('Upload Video')
  const onChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }
  const onSubmit = async (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append('title', formData.title)
    data.append('subtitle', formData.subtitle)
    data.append('summary', formData.summary)
    data.append('content', content)
    data.append('category', formData.category)
    data.append('file', formData.file)
    data.append('video', formData.video)
    try {
      const url = '/api/posts'
      const response = await fetch(url, {
        method: 'POST',
        body: data,
        credentials: 'include',
        onUploadProgress:(evt) => {
          const progress = (evt.loaded) / (evt.total) * 100
          setProgress(progress)
        }
      })
      if (response.ok) {
        setRedirect(true)
      }
      console.log(response)
    } catch (error) {
      console.log('Post create error', error)
    }
  }
  const handleVideo = async (e) => {
    try {
      const file = e.target.files[0]
      console.log(file)
      setUploadButtonText(file.name)
      setUploading(true)
   /*    const videoData = new FormData()
      videoData.append('video', file)
      const {data} = await axios.post(`/api/posts/video-upload`, videoData, {
        onUploadProgress: (e) => {
          setProgress(Math.round((100 * e.loaded) / e.total))
        }
      }) */
      setFormData({...formData, video:file })
    } catch (error) {
      
    }
  }
  if(!user) {
    return <Navigate to={'/login'} />
  }
  if (redirect) {
    return <Navigate to={'/'} />
  }


  return (
    <div className="min-h-screen bg-gray-50 py-10">
       <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
       <h1 className="text-2xl font-bold text-gray-800 mb-6">Create a New Post</h1>
      <form onSubmit={onSubmit} encType="multipart/form-data" className="space-y-6">
      <div>
            <label className="block text-gray-700 font-medium mb-2">Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={onChange}
              placeholder="Enter the post title"
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>

          <div>
            <label className="block text-gray-700 font-medium mb-2">Subtitle</label>
            <input
              type="text"
              name="subtitle"
              value={formData.subtitle}
              onChange={onChange}
              placeholder="Enter the post subtitle"
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>

          <div>
            <label className="block text-gray-700 font-medium mb-2">Summary</label>
            <textarea
              name="summary"
              value={formData.summary}
              onChange={onChange}
              placeholder="Write a brief summary of your post"
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
              rows="4"
            />
          </div>

          <div>
            <label className="block text-gray-700 font-medium mb-2">Category</label>
            <input
              type="text"
              name="category"
              value={formData.category}
              onChange={onChange}
              placeholder="Enter the category"
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>
          
          <div>
          <label className="block text-gray-700 font-medium mb-2">Content</label>
          <Editor
          theme='snow'
          value={content}
          name='content'
          onChange={setContent}
          className='editor-container border border-gray-300 rounded-md'
        />
          </div>
       
        {/* <textarea name="content" id="" cols="90" rows="10"></textarea> */}
        <div>
            <label className="block text-gray-700 font-medium mb-2">Upload Image</label>
            <input
              type="file"
              onChange={(e) => setFormData({ ...formData, file: e.target.files[0] })}
              accept="image/*"
              className="block w-full text-gray-700"
            />
          </div>

        
          {formData.file && (
            <div className="mt-4">
              <img
                src={URL.createObjectURL(formData.file)}
                alt="Preview"
                className="w-32 h-32 object-cover rounded-md"
              />
            </div>
          )}
         <br />
         {
          progress > 0 && <progress>{progress}%</progress>
         }

        {/* <input
          type='file'
          accept='video/*'
          onChange = {handleVideo}
        /> */}
        <button
            type="submit"
            className="w-full bg-blue-600 text-white font-medium py-3 rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-500"
          >
            Create Post
          </button>
      </form>
      </div>
    </div>
  )
}

export default CreatePost
