import React, { useContext, useState } from 'react'
import { Navigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { UserContext } from '../../context/UserContext'
import { Lock, Mail } from 'lucide-react'
import { Link } from 'react-router-dom'
import Loading from '../../components/shared/Loading'

export const LoginPage = () => {
  const initialState = {
    email: '',
    password: '',
  }

  const [formData, setFormData] = useState(initialState)
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const { setUser, setToken, setIsAuthenticated } = useContext(UserContext)

  const onChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
     try {
         setLoading(true)
        const url = '/api/users/login'
        const { data } = await axios.post(url, formData, { withCredentials: true })
        const token = data.token
        localStorage.setItem('token', token)
        setToken(token)
        setIsAuthenticated(true)
        setUser(data)
        setLoggedIn(true)

      } catch (error) {
        setLoading(false)
        setErrors(error.response.data)
        console.log('Login error', error)
        toast.error('Login failed. Please try again.')
      }
  }

  if (loggedIn) {
    return <Navigate to={'/'} />
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-indigo-500 to-purple-500">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <form onSubmit={onSubmit} className="space-y-6">
          <h1 className="text-3xl font-semibold text-center mb-6 text-gray-800">
            Log In
          </h1>

          {/* Email Input */}
          <div className="mb-4 relative">
            <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={onChange}
              placeholder="Enter your email"
              className={`w-full pl-10 py-3 px-4 rounded-lg border ${
                errors.email ? 'border-red-500' : 'border-gray-300'
              } focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300`}
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">{errors.email}</p>
            )}
          </div>

          {/* Password Input */}
          <div className="mb-6 relative">
            <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={onChange}
              placeholder="Enter your password"
              className={`w-full pl-10 py-3 px-4 rounded-lg border ${
                errors.password ? 'border-red-500' : 'border-gray-300'
              } focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300`}
            />
            {errors.password && (
              <p className="text-red-500 text-sm mt-1">{errors.password}</p>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={loading}
            className={`w-full py-3 px-4 bg-indigo-500 text-white rounded-lg font-semibold hover:bg-indigo-600 focus:ring-2 focus:ring-indigo-400 transition duration-300 ${
              loading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {loading ? <Loading /> : 'Log In'}
          </button>

          {/* Forgot Password Link */}
          <div className="text-center mt-4">
            <a
              href="/forgot-password"
              className="text-sm text-blue-500 hover:underline"
            >
              Forgot password?
            </a>
          </div>

          {/* Sign Up Link */}
          <div className="mt-4 text-center">
            <p className="text-sm text-gray-600">
              Don't have an account?{' '}
              <Link to={'/register'} className="text-blue-500 hover:underline">
                Sign Up
              </Link>
            </p>
          </div>
        </form>

        <ToastContainer />
      </div>
    </div>
  )
}

export default LoginPage
