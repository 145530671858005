import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from '../../../context/UserContext';
import { NavLink as Link, useNavigate } from 'react-router-dom';
import { Menu, Info, Database, User, LogOut, PlusCircle } from 'lucide-react';

const Header = ({ title }) => {
  const { user, setUser} = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const isLoggedIn = user ? true : false


  const toggle = () => setIsOpen(!isOpen);

  const logout = async () => {
    const url = '/api/users/logout';
    await fetch(url, { method: 'POST', credentials: 'include' });
    setUser(null);
    navigate('/login');
  };

  return (
    <header className='bg-indigo-700 text-white p-4 shadow-md'>
      <div className='max-w-7xl mx-auto flex justify-between items-center'>
        <Link to={'/'} className='text-2xl font-bold'>{title || '30 Days Of React'}</Link>
        <nav>
          <ul className='flex space-x-6'>
            <li className='flex items-center space-x-1'>
              <Menu className='w-5 h-5' />
              <Link to='/30-days-of-react' className={({ isActive }) => (isActive ? 'text-yellow-500' : 'text-white')}>Course</Link>
            </li>
            <li className='flex items-center space-x-1'>
              <Info className='w-5 h-5' />
              <Link to='/about' className={({ isActive }) => (isActive ? 'text-yellow-500' : 'text-white')}>About</Link>
            </li>
            {isLoggedIn && (
              <li className='flex items-center space-x-1'>
                <User className='w-5 h-5' />
                <Link to={`/profile/${user?._id}`} className={({ isActive }) => (isActive ? 'text-yellow-500' : 'text-white')}>Profile</Link>
              </li>
            )}
            {user && user.role[0] === 'Admin' && (
              <li className='flex items-center space-x-1'>
                <Database className='w-5 h-5' />
                <Link to='/dashboard' className={({ isActive }) => (isActive ? 'text-yellow-500' : 'text-white')}>Dashboard</Link>
              </li>
            )}
            {user && user.role[0] === 'Admin' && (
              <li className='flex items-center space-x-1'>
                <PlusCircle className='w-5 h-5' />
                <Link to='/30-days-of-react/create' className={({ isActive }) => (isActive ? 'text-yellow-500' : 'text-white')}>Add</Link>
              </li>
            )}
            {!user && (
              <>
                <li className='flex items-center space-x-1'>
                  <Link to='/login' className={({ isActive }) => (isActive ? 'text-yellow-500' : 'text-white')}>Login</Link>
                </li>
                <li className='flex items-center space-x-1'>
                  <Link to='/register' className={({ isActive }) => (isActive ? 'text-yellow-500' : 'text-white')}>Register</Link>
                </li>
              </>
            )}
            {user && (
              <li className='flex items-center space-x-1 cursor-pointer' onClick={logout}>
                <LogOut className='w-5 h-5' />
                <span>Logout</span>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.string,
};

export default Header;
